<template>
  <img v-if="!imageError" :alt="props.alt" :src="props.src" @error="handleImageError" />
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import httpRequest from '../../../helpers/httpRequest';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: false,
    default: '',
  },
  isStatic: {
    type: Boolean,
    default: false,
  },
});

const imageError = ref(false);

const url = ref(props.src);
if (!props.src.match(/^https?:/)) {
  url.value = url.value.replace('/api', '');
}

if (!props.isStatic) {
  await httpRequest.get(`${url.value}`).catch((err: any) => {
    imageError.value = true;
  });
}
</script>

<script lang="ts">
export default {
  name: 'SeImg',
};
</script>
